<template>
  <div class="screen">
    <div class="main flex row al-start">
      <div>{{ list.name }}</div>
      <div class="flex row start wrap industry">
        <div
          v-for="item2 in list.children"
          @click="clickRadio(item2.uuid)"
          :key="item2.uuid"
          :class="value === item2.uuid ? 'active' : ''"
        >
          {{ item2.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    list: {
      type: Object,
    },
  },
  data() {
    return {
      show: true,
    };
  },
  mounted() {},
  methods: {
    clickRadio(name) {
      this.$emit("input", name);
      this.$emit("change", name);
      this.$emit("testFn");
    },
  },
};
</script>

<style lang="less" scoped>
.screen {
  padding-bottom: 16px;
}
.main {
  justify-content: left;
  margin-left: 30px;
}
.main > div:nth-child(1) {
  line-height: 28px;
  left: 419px;
  top: 611px;
  width: 70px;
  height: 22px;
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: left;
  font-family: SourceHanSansSC-regular;
}
.main > div:nth-child(2) {
  width: 960px;

  .active {
    background-color: #156ed0;
    color: #ffffff !important;
  }
  div {
    margin: 0 20px 8px 0;
    font-size: 14px;
    color: #585858;
    cursor: pointer;
    padding: 5px 7px;
    border-radius: 3px;
    .active {
      color: #156ed0;
    }
    &:hover {
      color: #070707;
    }
  }
}
</style>
